<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <b-form-group>
            <label for="dtBaslangicFilter">
              {{ $t("Geçerlilik Başlangıç") }}
            </label>
            <b-form-input type="date" v-model="dtBaslangicFilter" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group>
            <label for="dtBitisFilter">
              {{ $t("Geçerlilik Bitiş") }}
            </label>
            <b-form-input type="date" v-model="dtBitisFilter" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>{{ $t("Arama") }}</label>
          <b-form-input
            v-model="queryFilter"
            class="d-inline-block mr-1"
            :placeholder="$t('Ara...')"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

export default {
  computed: {
    dtBaslangicFilter: {
      get() {
        return this.$store.getters['lisanssifrebilgiYonetim/getLogFilters'].dtLogBaslangic
      },
      set(value) {
        this.$store.commit('lisanssifrebilgiYonetim/setLogFilters', { key: 'dtLogBaslangic', value })
      },
    },
    dtBitisFilter: {
      get() {
        return this.$store.getters['lisanssifrebilgiYonetim/getLogFilters'].dtLogBitis
      },
      set(value) {
        this.$store.commit('lisanssifrebilgiYonetim/setLogFilters', { key: 'dtLogBitis', value })
      },
    },
    queryFilter: {
      get() {
        return this.$store.getters['lisanssifrebilgiYonetim/getLogFilters'].query
      },
      set(value) {
        this.$store.commit('lisanssifrebilgiYonetim/setLogFilters', { key: 'query', value })
      },
    },
  },
}
</script>
