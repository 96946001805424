import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function useLogs() {
  const logsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'Firma Adı', key: 'sFirmaAdi', sortable: true },
    { label: 'VKN', key: 'sVkn', sortable: true },
    { label: 'Lisans No', key: 'sLisansNo', sortable: true },
    { label: 'IP', key: 'sIpAdres', sortable: true },
    { label: 'Makina Kodu', key: 'sMakinaKodu', sortable: true },
    { label: 'Program', key: 'sProgamAdi', sortable: true },
    { label: 'Aksiyon', key: 'actions' },
  ]

  const perPage = ref(10)
  const toplamLogs = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters['lisanssifrebilgiYonetim/getLogFilters'].sortBy
    },
    set(value) {
      store.commit('lisanssifrebilgiYonetim/setLogFilters', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters['lisanssifrebilgiYonetim/getLogFilters'].sortDesc
    },
    set(value) {
      store.commit('lisanssifrebilgiYonetim/setLogFilters', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = logsTable.value ? logsTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamLogs.value,
    }
  })

  const refetchData = () => {
    logsTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchLogs = query => {
    store.dispatch('lisanssifrebilgiYonetim/fetchLogs', query)
  }

  const getLogs = computed(() => {
    const logs = store.getters['lisanssifrebilgiYonetim/getLogs']
    toplamLogs.value = logs.length
    return logs
  })

  onMounted(fetchLogs)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusIcon = status => {
    if (status === false) return 'XIcon'
    if (status === true) return 'CheckIcon'
    return 'UserIcon'
  }

  const resolveStatusName = status => {
    if (status === false) return 'GEÇERSİZ'
    if (status === true) return 'GEÇERLİ'
    return 'TANIMLI DEĞİL'
  }

  const resolveStatusVariant = status => {
    if (status === false) return 'warning'
    if (status === true) return 'success'
    return 'primary'
  }

  return {
    fetchLogs,
    getLogs,
    tableColumns,
    perPage,
    currentPage,
    toplamLogs,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    logsTable,

    resolveStatusIcon,
    resolveStatusName,
    resolveStatusVariant,
  }
}
