<template>
  <div>
    <crud-button :showAddNew="false" :onClickList="fetchLogs" />

    <log-filters></log-filters>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("tane göster") }}</label>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="logsTable"
        class="position-relative"
        :items="getLogs"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Hiçbir eşleşen kayıt bulunamadı"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Musteri -->
        <template #cell(sFirmaAdi)="data">
          <span v-if="data.item.sFirmaAdi.length <= 30">
            {{ data.item.sFirmaAdi }}
          </span>
          <span v-else v-b-tooltip.hover.v-primary :title="data.item.sFirmaAdi">
            {{ data.item.sFirmaAdi.substring(0, 30) + "..." }}
          </span>
        </template>

        <!-- Column: Kodu -->
        <template #cell(sVkn)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sVkn }}</span>
          </div>
        </template>

        <!-- Column: Vergi -->
        <template #cell(sLisansNo)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sLisansNo }}</span>
          </div>
        </template>

        <!-- Column: Telefon -->
        <template #cell(sMakinaKodu)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sMakinaKodu }}</span>
          </div>
        </template>

        <!-- Column: Yetkili -->
        <template #cell(sProgamAdi)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              data.item.sProgamAdi + " " + data.item.sProgamVersion
            }}</span>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >{{ dataMeta.of }} öğeden {{ dataMeta.from }} -
              {{ dataMeta.to }} arası gösteriliyor</span
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="toplamLogs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import LogFilters from './LogFilters.vue'
import useLogs from './useLogs'

export default {
  components: {
    LogFilters,
  },

  setup() {
    const {
      fetchLogs,
      getLogs,
      tableColumns,
      perPage,
      currentPage,
      toplamLogs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      logsTable,

      resolveStatusIcon,
      resolveStatusName,
      resolveStatusVariant,
    } = useLogs()

    return {
      fetchLogs,
      getLogs,
      tableColumns,
      perPage,
      currentPage,
      toplamLogs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      logsTable,

      resolveStatusIcon,
      resolveStatusName,
      resolveStatusVariant,
    }
  },

}
</script>

<style>
</style>
